import styled, { css } from 'styled-components';

import { CollapsePanelGroup, PriceRange, WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components,
  FontSize,
  FontFamily,
  GlobalLayoutValues,
} from '@powdr/constants';
import theme from '@powdr/skisilverstar/theme/theme';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.MODAL;

export const StyledTemplatedContent = styled.div``;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7.5%;
  margin: 0 round(nearest, 7.5%, 1px);

  ${Breakpoints.mobile(css`
    margin: 0 3.5%;
    margin: 0 round(nearest, 4.5%, 1px);
  `)}
`;

export const MaxWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  width: 100%;
  max-width: ${GlobalLayoutValues.DESKTOP.WRAPPER_MAX}px;
  padding-bottom: 90px;
`;

export const RowSection = styled.div`
  display: flex;
  width: 100%;
  gap: 6%;

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
    gap: 15px;
  `)}
`;

export const ContentContainer = styled.div`
  width: ${({ $splitContent }) => (($splitContent) ? '50%' : '100%')};

  ${Breakpoints.mobile(css`
    padding-right: 0;
    width: 100%;
  `)}
`;

export const SplitContentContainer = styled.div`
  width: 50%;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

export const DynamicTitle = styled.h2`
  color: ${() => themeUtil(Prop.HEADER, CPNT)};
  margin-bottom: 20px;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG12, undefined, undefined)};
`;

export const DynamicBody = styled(WysiwygStyleWrapper)`
  width: 100%;
`;

export const DynamicBodySplit = styled(WysiwygStyleWrapper)`
  width: 100%;
  margin-top: 25px;

  ${Breakpoints.desktopOnly(css`
    &.mobile-sidebar-content {
      display: none;
    }
  `)}

  ${Breakpoints.mobile(css`
    &.desktop-sidebar-content {
      display: none;
    }
  `)}
`;

export const LocationHoursContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 40px;
`;

export const DynamicContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  line-height: 1.1;

  a {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${() => themeUtil(Prop.LINK_TXT, CPNT)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      outline-offset: 2px;
    }
  }
`;

export const LocationSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactInfoSection = styled.div``;

export const DynamicHours = styled(WysiwygStyleWrapper)`
  p {
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin: 0 !important;
  }
`;

export const DynamicPrice = styled(PriceRange)`
  font-weight: bold;

  ${Breakpoints.mobile(css`
    margin-bottom: 10px;
  `)}
`;

export const DynamicMenu = styled(WysiwygStyleWrapper)`
  margin-top: 20px;
`;

export const NewsStyleDate = styled.p``;

export const ShortDesc = styled.div`
  margin-bottom: 15px;
`;

export const NewsStyleImage = styled.img``;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  svg {
    fill: ${() => themeUtil(Prop.ICON, CPNT)};
    color: ${() => themeUtil(Prop.ICON, CPNT)};
  }
`;

export const StartEndDate = styled.h2`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  margin-left: 15px;
`;

export const TextGallery = styled.div`
  display: flex;
  gap: 10%;
`;

export const Dropdowns = styled(CollapsePanelGroup)`
  padding: 25px 25px 10px;
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownsWrapperHeader = styled.h2`
  color: ${() => themeUtil(Prop.HEADER, CPNT)};
  margin-bottom: 15px;
`;
