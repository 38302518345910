import styled from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, FontSize,
  FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.MAIN_NAV;

export const StyledChildNavDrawer = styled.div`
  position: absolute;
  top: 0;
  z-index: 80; // places drawer on top of header
  height: 100%;
  width: 100%;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BACKGROUND)};
  transition: transform 0.5s ease-in-out;
  overflow: hidden;

  ${({ $isOpen }) => ($isOpen
    ? 'transform: translateX(0);'
    : 'transform: translateX(100%);')}
`;

export const DrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  align-items: center;
`;

export const ParentName = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 0;

  .parent-name-icon {
    height: ${FontSize.MED20};
    width: ${FontSize.MED20};
    margin-right: 8px;

    svg {
      fill: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
      color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
    }
  }
`;

export const NameText = styled.div`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED20, undefined, undefined)};
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
  line-height: 1.1;
`;

export const ChildNavList = styled.ul`
  width: 100%;
`;

export const ChildNavItem = styled.li``;

export const ChildNavItemLink = styled(Link)`
  display: block;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
  line-height: 1.1;
  margin-bottom: 8px;
  width: 100%;
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
`;
