/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  Components, Breakpoints, ThemeProps as Prop, GlobalLayoutValues,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.UI_SECTION;

export const StyledUiSection = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  transition: 1s background-color linear;

  ${({ scrollOffset }) => ((scrollOffset) ? `
    scroll-behavior: smooth;
    scroll-margin-top: ${scrollOffset}px;
  ` : '')}


  ${({ backgroundImage, bgImageHPosition, bgImagePosition }) => backgroundImage && css`
    background-image: url('${backgroundImage}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${bgImageHPosition || 'center'} ${bgImagePosition || 'center'};
  `}
`;

export const OuterWrapper = styled.div`
  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({
    $topPadding, $bottomPadding, $sidePadding,
    hasUiFooterAsChild, hasUiHeaderAsChild, hasNoPaddingGrid,
    isContainer, isMobile,
  }) => {
    let leftRightPaddingPercentage = 0;
    let topPaddingPx = 50;
    let bottomPaddingPx = 50;

    // handle left right padding logic
    if (!$sidePadding) {
      // legacy support/fallback for container toggle
      if (isContainer) {
        if (isMobile) leftRightPaddingPercentage = 4.5;
        else leftRightPaddingPercentage = 7.5;
      }
    } else if (isMobile) leftRightPaddingPercentage = 4.5;
    else leftRightPaddingPercentage = $sidePadding;

    // handle top padding
    if ($topPadding === null) {
      if (hasUiHeaderAsChild || hasNoPaddingGrid) topPaddingPx = 0;
    } else topPaddingPx = $topPadding;

    // handle bottom padding
    if ($bottomPadding === null) {
      if (hasUiFooterAsChild || hasNoPaddingGrid) bottomPaddingPx = 0;
    } else bottomPaddingPx = $bottomPadding;

    return `
      margin: ${topPaddingPx}px ${leftRightPaddingPercentage}% ${bottomPaddingPx}px;
      margin: ${topPaddingPx}px round(nearest, ${leftRightPaddingPercentage}%, 1px) ${bottomPaddingPx}px;
    `;
  }}

  .temp-section-header {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG10, undefined, undefined)};
    text-align: center;
  }

  // TODO: Test larger wrapped breakpoints
  ${Breakpoints.desktopOnly(css`
    .trapezoid-overlay {
      transform: skewX(-20deg);
      pointer-events: none;
      position: absolute;
      border: 1px black solid;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .trapezoid-overlay + .section-inner .ui-column, .trapezoid-overlay + .section-inner .ui-column > ui-image {
      padding: 0;

      .ui-image {
        padding: 0;

        img {
          margin: 0;
        }
      }

      .ui-html {
        padding-left: 100px;
      }
    }
  `)}
`;

export const MaxWidthWrapper = styled.div`
  display: flex;
  width: 100%;
  ${({ $isIgnoreMaxWidth }) => (!$isIgnoreMaxWidth) && `max-width: ${GlobalLayoutValues.DESKTOP.WRAPPER_MAX}px;`};
`;

export const ComponentWrapper = styled.div`
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  .loader-404 {
    height: auto;
    width: auto;
  }
`;
