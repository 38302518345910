/**
 * Contains hooks that handle the state and actions of the different tabs we render on each site.
 *
 * Each hook is expected to extend the functionality of the `useTabState` hook.
 * The `useTabState` hook contains all the state needed by each custom hook.
 * Each custom hook is then responsible for mapping the state to the intended output or behavior.
 */
import { format, differenceInCalendarDays, addDays } from 'date-fns';
import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import { useIsMountedRef } from '@powdr/hooks';

/**
 * @typedef   {Object}    TabState
 * @property  {Object}    tabValues       the state object containing the tab values
 * @property  {Function}  setTabValues    the tab values updater function
 * @property  {string}    buttonLink     the query string to append to external URLs
 * @property  {string}    setButtonLink  the query string updater function
 */

/**
 * A shared hook that manages/controls the values of the fields rendered by a tab
 * @returns {TabState} a tuple of the state and state update function
 */
const useTabState = () => {
  const [tabValues, setTabValues] = useState({});
  const [buttonLink, setButtonLink] = useState('');
  const isMountedRef = useIsMountedRef();

  return {
    tabValues,
    setTabValues: useCallback((updatedState) => {
      if (!isMountedRef.current) return;
      setTabValues((prevState) => ({ ...prevState, ...updatedState }));
    }, []),
    buttonLink,
    setButtonLink: useCallback((updatedQueryString) => {
      if (!isMountedRef.current) return;
      setButtonLink(updatedQueryString);
    }, []),
  };
};

/**
 * @typedef   {Object}    CustomTabState  a map of state and state update functions
 *                                        that are needed to control tab values and actions
 * @property  {Object?}   tabState        an object containing the tab fields' current values
 * @property  {string?}   buttonLink     a URL query string to apply to external tab links
 * @property  {Function}  setTabState     a function to update the tab fields' values
 */

/**
 * A custom hook for `Lodging` tab type (handling specifically Spotlio)
 * @returns {CustomTabState} a map of state and state update functions for all `Lodging` tabs
 */
export const useSpotlioLodging = ({
  commerceEngineUrl,
  lodgingDepartureOffest,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();
  const components = [
    {
      name: 'arrivaldate',
      label: 'Arrival',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: true,
      placeholder: 'MM/DD/YYYY',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'nights',
      label: 'Depart',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: false,
      placeholder: 'MM/DD/YYYY',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'adults',
      label: 'Adult Guests',
      type: 'select',
      placeholder: 'Adults',
      defaultValue: { value: 1, label: '1 Adult' },
      choices: [
        { value: 0, label: 'No Adults' },
        { value: 1, label: '1 Adult' },
        { value: 2, label: '2 Adults' },
        { value: 3, label: '3 Adults' },
        { value: 4, label: '4 Adults' },
        { value: 5, label: '5 Adults' },
        { value: 6, label: '6 Adults' },
        { value: 7, label: '7 Adults' },
        { value: 8, label: '8 Adults' },
        { value: 9, label: '9 Adults' },
      ],
    },
    {
      name: 'children',
      label: 'Child Guests',
      type: 'select',
      placeholder: 'Children',
      defaultValue: { value: 0, label: 'No Children' },
      choices: [
        { value: 0, label: 'No Children' },
        { value: 1, label: '1 Child' },
        { value: 2, label: '2 Children' },
        { value: 3, label: '3 Children' },
        { value: 4, label: '4 Children' },
        { value: 5, label: '5 Children' },
        { value: 6, label: '6 Children' },
        { value: 7, label: '7 Children' },
        { value: 8, label: '8 Children' },
        { value: 9, label: '9 Children' },
      ],
    },
  ];

  useEffect(() => {
    const queries = [];

    if (tabValues[components[0].name]) {
      const { selectedDate, minDate } = tabValues[components[0].name];
      queries.push(
        `${components[0].name}=${encodeURIComponent(
          format(selectedDate || minDate, 'MM/dd/yyyy'),
        )}`,
      );
    }

    if (tabValues[components[0].name] && tabValues[components[1].name]) {
      const {
        minDate: minArrivalDate,
        selectedDate: selectedArrivalDate,
      } = tabValues[components[0].name];
      const {
        minDate: minDepartureDate,
        selectedDate: selectedDepartureDate,
      } = tabValues[components[1].name];
      queries.push(
        `${components[1].name}=${
          Math.abs(
            differenceInCalendarDays(
              selectedArrivalDate || minArrivalDate,
              selectedDepartureDate || minDepartureDate,
            ),
          )
        }`,
      );
    }

    if (tabValues[components[2].name]) {
      queries.push(`${components[2].name}=${tabValues[components[2].name].value}`);
    }

    if (tabValues[components[3].name]) {
      queries.push(`${components[3].name}=${tabValues[components[3].name].value}`);
    }

    if (
      tabValues[components[0].name]
      && tabValues[components[1].name]
      && tabValues[components[2].name]
    ) {
      const updatedQueryString = queries.join('&');
      setButtonLink(`${commerceEngineUrl}?${updatedQueryString}`);
    }
  }, [tabValues]);

  const customSetTabValues = useCallback((update) => {
    const updatedTabValues = { ...update };

    // Set the min date of the departureDate field to the selected date of the arrivalDate field
    if (updatedTabValues[components[0].name]?.selectedDate) {
      updatedTabValues[components[1].name] = {
        ...(tabValues[components[1].name] ?? {}),
        isAutoSelected: true,
        minDate: addDays(
          updatedTabValues[components[0].name].selectedDate,
          lodgingDepartureOffest || 1,
        ),
      };
    }

    setTabValues(updatedTabValues);
  }, [tabValues]);

  if (tabValues[components[2].name] === undefined) {
    tabValues[components[2].name] = {
      ...components[2].choices[1],
    };
  }

  return {
    tabValues,
    setTabValues: customSetTabValues,
    buttonLink,
    components,
  };
};

/**
 * A custom hook for `Lodging` tab type (handling specifically Guestdesk found on Snowbird)
 * @returns {CustomTabState} a map of state and state update functions for all `Lodging` tabs
 */
export const useGuestdeskSelectableProperty = ({
  commerceEngineUrl,
  lodgingDepartureOffest,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();
  const components = [
    {
      name: 'checkin',
      label: 'Arrival',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: true,
      placeholder: 'MM/DD/YYYY',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'checkout',
      label: 'Departure',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: false,
      placeholder: 'MM/DD/YYYY',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'adults',
      label: 'Adults',
      type: 'select',
      placeholder: 'Adults',
      defaultValue: { value: 1, label: '1 Adult' },
      choices: [
        { value: 0, label: 'No Adults' },
        { value: 1, label: '1 Adult' },
        { value: 2, label: '2 Adults' },
        { value: 3, label: '3 Adults' },
        { value: 4, label: '4 Adults' },
        { value: 5, label: '5 Adults' },
        { value: 6, label: '6 Adults' },
        { value: 7, label: '7 Adults' },
        { value: 8, label: '8 Adults' },
        { value: 9, label: '9 Adults' },
      ],
    },
    {
      name: 'children',
      label: 'Child',
      type: 'select',
      placeholder: 'Children',
      defaultValue: { value: 0, label: 'No Children' },
      choices: [
        { value: 0, label: 'No Children' },
        { value: 1, label: '1 Child' },
        { value: 2, label: '2 Children' },
        { value: 3, label: '3 Children' },
        { value: 4, label: '4 Children' },
        { value: 5, label: '5 Children' },
        { value: 6, label: '6 Children' },
        { value: 7, label: '7 Children' },
        { value: 8, label: '8 Children' },
        { value: 9, label: '9 Children' },
      ],
    },
    {
      name: 'selectedProperty',
      label: 'Property',
      type: 'select',
      placeholder: 'Property',
      defaultValue: { value: null, label: 'Select' },
      choices: [
        { value: null, label: 'Any Property' },
        { value: 'TheCliffLodge', label: 'The Cliff Lodge' },
        { value: 'IronBlosamLodge', label: 'Iron Blosam Lodge' },
        { value: 'TheCliffClub', label: 'The Cliff Club' },
        { value: 'TheInnatSnowbird', label: 'The Inn at Snowbird' },
        { value: 'TheLodgeatSnowbird', label: 'The Lodge at Snowbird' },
      ],
    },
  ];

  useEffect(() => {
    const queries = [];

    if (tabValues[components[4].name]?.value) {
      queries.push(`selectedProperty=${tabValues[components[4].name].value}`);
    }

    if (tabValues[components[0].name]) {
      const { selectedDate, minDate } = tabValues[components[0].name];
      queries.push(
        `${components[0].name}=${encodeURIComponent(
          format(selectedDate || minDate, 'MM/dd/yyyy'),
        )}`,
      );
    }

    if (tabValues[components[1].name]) {
      const { selectedDate, minDate } = tabValues[components[1].name];
      queries.push(
        `${components[1].name}=${encodeURIComponent(
          format(selectedDate || minDate, 'MM/dd/yyyy'),
        )}`,
      );
    }

    if (tabValues[components[2].name]) {
      queries.push(`${components[2].name}=${tabValues[components[2].name].value}`);
    }

    if (tabValues[components[3].name]) {
      queries.push(`${components[3].name}=${tabValues[components[3].name].value}`);
    }

    if (
      tabValues[components[0].name]
      && tabValues[components[1].name]
      && tabValues[components[2].name]
    ) {
      const updatedQueryString = queries.join('&');
      setButtonLink(`${commerceEngineUrl}?${updatedQueryString}`);
    }
  }, [tabValues]);

  const customSetTabValues = useCallback((update) => {
    const updatedTabValues = { ...update };

    // Set the min date of the departureDate field to the selected date of the arrivalDate field
    if (updatedTabValues[components[0].name]?.selectedDate) {
      updatedTabValues[components[1].name] = {
        ...(tabValues[components[1].name] ?? {}),
        isAutoSelected: true,
        minDate: addDays(
          updatedTabValues[components[0].name].selectedDate,
          lodgingDepartureOffest || 1,
        ),
      };
    }

    setTabValues(updatedTabValues);
  }, [tabValues]);

  if (tabValues[components[2].name] === undefined) {
    tabValues[components[2].name] = {
      ...components[2].choices[1],
    };
  }

  return {
    tabValues,
    setTabValues: customSetTabValues,
    buttonLink,
    components,
  };
};

/**
 * A custom hook for `Lodging` tab type (handling specifically Guestdesk found on Snowbird)
 * @returns {CustomTabState} a map of state and state update functions for all `Lodging` tabs
 */
export const useGuestdeskStaticProperty = ({
  commerceEngineUrl,
  lodgingDepartureOffest,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();
  const components = [
    {
      name: 'checkin',
      label: 'Arrival',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: true,
      placeholder: 'MM/DD/YYYY',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'checkout',
      label: 'Depart',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: false,
      placeholder: 'MM/DD/YYYY',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'adults',
      label: 'Adult Guests',
      type: 'select',
      placeholder: 'Adults',
      defaultValue: { value: 1, label: '1 Adult' },
      choices: [
        { value: 0, label: 'No Adults' },
        { value: 1, label: '1 Adult' },
        { value: 2, label: '2 Adults' },
        { value: 3, label: '3 Adults' },
        { value: 4, label: '4 Adults' },
        { value: 5, label: '5 Adults' },
        { value: 6, label: '6 Adults' },
        { value: 7, label: '7 Adults' },
        { value: 8, label: '8 Adults' },
        { value: 9, label: '9 Adults' },
      ],
    },
    {
      name: 'children',
      label: 'Child Guests',
      type: 'select',
      placeholder: 'Children',
      defaultValue: { value: 0, label: 'No Children' },
      choices: [
        { value: 0, label: 'No Children' },
        { value: 1, label: '1 Child' },
        { value: 2, label: '2 Children' },
        { value: 3, label: '3 Children' },
        { value: 4, label: '4 Children' },
        { value: 5, label: '5 Children' },
        { value: 6, label: '6 Children' },
        { value: 7, label: '7 Children' },
        { value: 8, label: '8 Children' },
        { value: 9, label: '9 Children' },
      ],
    },
  ];

  useEffect(() => {
    const queries = [];

    if (tabValues[components[0].name]) {
      const { selectedDate, minDate } = tabValues[components[0].name];
      queries.push(
        `${components[0].name}=${encodeURIComponent(
          format(selectedDate || minDate, 'MM/dd/yyyy'),
        )}`,
      );
    }

    if (tabValues[components[1].name]) {
      const { selectedDate, minDate } = tabValues[components[1].name];
      queries.push(
        `${components[1].name}=${encodeURIComponent(
          format(selectedDate || minDate, 'MM/dd/yyyy'),
        )}`,
      );
    }

    if (tabValues[components[2].name]) {
      queries.push(`${components[2].name}=${tabValues[components[2].name].value}`);
    }

    if (tabValues[components[3].name]) {
      queries.push(`${components[3].name}=${tabValues[components[3].name].value}`);
    }

    if (
      tabValues[components[0].name]
      && tabValues[components[1].name]
      && tabValues[components[2].name]
    ) {
      const updatedQueryString = queries.join('&');
      setButtonLink(`${commerceEngineUrl}&${updatedQueryString}`);
    }
  }, [tabValues]);

  const customSetTabValues = useCallback((update) => {
    const updatedTabValues = { ...update };

    // Set the min date of the departureDate field to the selected date of the arrivalDate field
    if (updatedTabValues[components[0].name]?.selectedDate) {
      updatedTabValues[components[1].name] = {
        ...(tabValues[components[1].name] ?? {}),
        isAutoSelected: true,
        minDate: addDays(
          updatedTabValues[components[0].name].selectedDate,
          lodgingDepartureOffest || 1,
        ),
      };
    }

    setTabValues(updatedTabValues);
  }, [tabValues]);

  if (tabValues[components[2].name] === undefined) {
    tabValues[components[2].name] = {
      ...components[2].choices[1],
    };
  }

  return {
    tabValues,
    setTabValues: customSetTabValues,
    buttonLink,
    components,
  };
};

/**
 * A custom hook for `Lodging` tab type (handling specifically iQ-Webbook found on Stovepipe Wells)
 * @returns {CustomTabState} a map of state and state update functions for all `Lodging` tabs
 */
export const useiQWebbookLodging = ({
  commerceEngineUrl,
  lodgingDepartureOffest,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();

  const components = [
    {
      name: 'arrivalDate',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: true,
      placeholder: 'Arrive',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'departureDate',
      type: 'datepicker',
      isDefaultDate: false,
      isCurrentDaySelectable: false,
      placeholder: 'Depart',
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'adultsCount',
      type: 'select',
      placeholder: '# of Adults',
      defaultValue: { value: 1, label: '1 Adult' },
      choices: [
        { value: 0, label: 'No Adults' },
        { value: 1, label: '1 Adult' },
        { value: 2, label: '2 Adults' },
        { value: 3, label: '3 Adults' },
        { value: 4, label: '4 Adults' },
        { value: 5, label: '5 Adults' },
        { value: 6, label: '6 Adults' },
        { value: 7, label: '7 Adults' },
        { value: 8, label: '8 Adults' },
        { value: 9, label: '9 Adults' },
      ],
    },
    {
      name: 'childrenCount',
      type: 'select',
      placeholder: '# of Children',
      defaultValue: { value: 0, label: 'No Children' },
      choices: [
        { value: 0, label: 'No Children' },
        { value: 1, label: '1 Child' },
        { value: 2, label: '2 Children' },
        { value: 3, label: '3 Children' },
        { value: 4, label: '4 Children' },
        { value: 5, label: '5 Children' },
        { value: 6, label: '6 Children' },
        { value: 7, label: '7 Children' },
        { value: 8, label: '8 Children' },
        { value: 9, label: '9 Children' },
      ],
    },
  ];

  useEffect(() => {
    const queries = [];

    if (tabValues[components[0].name]) {
      const { selectedDate, minDate } = tabValues[components[0].name];
      const dateString = format(selectedDate || minDate, 'EEE, MMM d yyyy');
      queries.push(`${components[0].name}=${encodeURIComponent(dateString)}`);
    }

    if (tabValues[components[0].name] && tabValues[components[1].name]) {
      const {
        minDate: minDepartureDate,
        selectedDate: selectedDepartureDate,
      } = tabValues[components[1].name];
      queries.push(
        `${components[1].name}=${
          encodeURIComponent(
            format(selectedDepartureDate || minDepartureDate, 'EEE, MMM d yyyy'),
          )
        }`,
      );
    }
    if (tabValues[components[2].name] && tabValues[components[3].name]) {
      queries.push(`rooms=[{${encodeURIComponent(`"${components[2].name}":${tabValues[components[2].name].value}, "${components[3].name}":${tabValues[components[3].name].value}`)}}]`);
    }

    if (
      tabValues[components[0].name]
      && tabValues[components[1].name]
      && tabValues[components[2].name]
      && tabValues[components[3].name]
    ) {
      const updatedQueryString = queries.join('&');
      setButtonLink(`${commerceEngineUrl}?${updatedQueryString}`);
    }
  }, [tabValues]);

  const customSetTabValues = useCallback((update) => {
    const updatedTabValues = { ...update };

    // Set the min date of the departureDate field to the selected date of the arrivalDate field
    if (updatedTabValues[components[0].name]?.selectedDate) {
      updatedTabValues[components[1].name] = {
        ...(tabValues[components[1].name] ?? {}),
        isAutoSelected: true,
        minDate: addDays(
          updatedTabValues[components[0].name].selectedDate,
          lodgingDepartureOffest || 1,
        ),
      };
    }

    setTabValues(updatedTabValues);
  }, [tabValues]);

  // set the default values for adult/child fields
  if (tabValues[components[2].name] === undefined) {
    tabValues[components[2].name] = {
      ...components[2].defaultValue,
    };
  }

  if (tabValues[components[3].name] === undefined) {
    tabValues[components[3].name] = {
      ...components[3].defaultValue,
    };
  }

  return {
    tabValues,
    setTabValues: customSetTabValues,
    buttonLink,
    components,
  };
};

/**
 * A custom hook for Single Date tab type
 * @returns {CustomTabState} a map of state and state update functions for all `Single ` tabs
 */
export const useSingleDate = ({
  commerceEngineUrl,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();
  const components = [{
    name: 'single_date',
    type: 'datepicker',
    isDefaultDate: false,
    placeholder: 'Select Date',
    isCurrentDaySelectable: true,
    maxDaysToBookAhead: 550,
    prettyDate: 'MMM dd yyyy',
    dateFormat: 'MM/dd/yyyy',
  }];

  useEffect(() => {
    if (tabValues[components[0].name]) {
      const { minDate, selectedDate } = tabValues[components[0].name];

      setButtonLink(`${commerceEngineUrl}${format(selectedDate || minDate, 'yyyy-MM-dd')}`);
    }
  }, [tabValues]);

  return {
    setTabValues,
    buttonLink,
    components,
  };
};

/**
 * A custom hook for Link Selector tab type (can also handle button only tabs)
 * @returns {CustomTabState} a map of state and state update functions for all `Link Selector` tabs
 */
export const useLinkSelector = ({
  commerceEngineUrl,
  dropdownOptions,
  selectableItemType,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();
  const components = (dropdownOptions?.length > 0)
    ? [
      {
        name: 'single_item_date',
        type: selectableItemType || 'select',
        isHideLabel: true,
        placeholder: 'Select an option...',
        choices: dropdownOptions
          .map((option) => ({ label: option.buttonText, value: option.buttonLink })),
      },
    ]
    : [];

  useEffect(() => {
    if (tabValues?.[components[0]?.name]?.value || components.length === 0) {
      setButtonLink(`${(commerceEngineUrl || '')}${tabValues?.[components[0]?.name]?.value || ''}`);
    }
  }, [tabValues]);

  return {
    setTabValues,
    buttonLink,
    components,
  };
};

/**
 * A custom hook for Aspenware Link(s) with Date tab type
 * @returns {CustomTabState} a map of state and state update functions for
 *  all `Aspenware Link(s) with Date` tabs
 */
export const useAspenwareLinksDate = ({
  commerceEngineUrl,
  dropdownOptions,
}) => {
  const {
    tabValues,
    setTabValues,
    buttonLink,
    setButtonLink,
  } = useTabState();
  const components = [
    {
      type: 'datepicker',
      name: 'start_date',
      placeholder: 'Start Date',
      isDefaultDate: false,
      isCurrentDaySelectable: true,
      maxDaysToBookAhead: 550,
      prettyDate: 'MMM dd yyyy',
      dateFormat: 'MM/dd/yyyy',
    },
    {
      name: 'product_name',
      type: 'select',
      placeholder: 'Select a product...',
      choices: dropdownOptions
        .map((option) => ({
          label: option.buttonText,
          value: option.buttonLink,
          productId: option.productId,
        }
        )),
    },
  ];

  useEffect(() => {
    if (tabValues[components[0].name] && tabValues[components[1].name].value) {
      const { minDate, selectedDate } = tabValues[components[0].name];
      setButtonLink(`${commerceEngineUrl}${tabValues[components[1].name].value}?${tabValues[components[1].name]?.productId}=${format(selectedDate || minDate, 'yyyy-MM-dd')}`);
    }
  }, [tabValues]);

  return {
    setTabValues,
    buttonLink,
    components,
  };
};
