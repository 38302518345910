import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect, useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Link } from '@powdr/components';
import { HideOptions, DateFormats, Layouts } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getConditions } from '@powdr/stores/actions/conditions-action';
import { slugify, getSnowfallMeasurementUnit, capitalizeAllWords } from '@powdr/utils';

import {
  StyledDorSnowReport,
  StaticContent,
  ReportDataItems,
  ReportDataItem,
  Timestamp,
  DataSection,
  ReportSection,
  ReportContentWrapper,
  ItemAlignmentWrapper,
  ItemIcon,
} from './styles';

export const DorSnowReport = ({
  hide,
  layout,
  title,
  html,
  isParentNavFeed,
  navFeedLink,
  filterBy,
  locationId,
  showIcons,
  parentColorProfile,
}) => {
  const {
    setDrawerOpen,
    setDorDrawerOpen,
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const useConditionsData = useSelector((state) => state.conditions);
  const { data } = useConditionsData;
  const [conditionsData, setConditionsData] = useState({});
  const measurementUnit = getSnowfallMeasurementUnit();

  useEffect(() => {
    dispatch(getConditions());
  }, [dispatch]);

  useEffect(() => {
    if (data?.dorSnowReport) {
      // TODO: Move to own constant
      const PRIMARY = 'Primary';
      const defaultCond = data?.dorSnowTotals
        ?.filter((condition) => condition.measurement_location_name === PRIMARY)
        || [];

      const cond = data?.dorSnowTotals
        ?.filter((condition) => locationId
          ?.includes(String(condition.location_id)))
        || [];

      const [condReturn] = cond.length > 0
        ? cond
        : defaultCond;

      setConditionsData({
        ...condReturn,
        items: condReturn.items?.filter((item) => filterBy
          .map((filterItem) => slugify(filterItem))
          .includes(slugify(item.duration))),
      });
    }
  }, [data, locationId, filterBy]);

  return (
    <StyledDorSnowReport
      colorProfile={parentColorProfile}
      className="dor-snow-totals"
      $layout={layout || Layouts.STACKED}
      $isParentNavFeed={isParentNavFeed}
    >
      <DataSection>
        {((title || html)) && (
          <StaticContent colorProfile={parentColorProfile}>
            {title && (<h3>{title}</h3>)}
            {html && ReactHtmlParser(html)}
          </StaticContent>
        )}
        {conditionsData?.items && (
          <ReportDataItems
            $layout={layout || Layouts.STACKED}
            $numItems={conditionsData.items.length}
            $isParentNavFeed={isParentNavFeed}
          >
            {conditionsData?.items.map((item, idx) => (
              <ReportDataItem
                key={slugify(item.duration)}
                $layout={layout || Layouts.STACKED}
                $colorProfile={parentColorProfile}
                $index={idx + 1}
                $numItems={conditionsData.items.length}
              >
                <ItemAlignmentWrapper>
                  <h3 className="duration">
                    {capitalizeAllWords(item.duration)}
                  </h3>
                  {(showIcons) && <ItemIcon name="dor-snow" />}
                  <p className="amount">
                    {item.amount}
                    {measurementUnit}
                  </p>
                </ItemAlignmentWrapper>
              </ReportDataItem>
            ))}
          </ReportDataItems>
        )}
      </DataSection>
      {(conditionsData?.title || conditionsData?.date || conditionsData?.html || navFeedLink) && (
        <ReportSection colorProfile={parentColorProfile}>
          {(conditionsData?.date && !hide.includes(HideOptions.DATE)) && (
            <Timestamp colorProfile={parentColorProfile}>
              {format(parseISO(conditionsData.date), DateFormats.FULL_DATE_LONG)}
            </Timestamp>
          )}
          {(conditionsData?.title && !hide.includes(HideOptions.TITLE))
            && <h3>{conditionsData?.title}</h3>}
          {(conditionsData?.html && !hide.includes(HideOptions.HTML)) && (
            <ReportContentWrapper $isParentNavFeed={isParentNavFeed}>
              {ReactHtmlParser(conditionsData.html)}
            </ReportContentWrapper>
          )}
          {(navFeedLink?.href) && (
            <Link onClick={() => navFeedLink.onClick()} href={navFeedLink.href}>
              {navFeedLink.text}
            </Link>
          )}
        </ReportSection>
      )}
    </StyledDorSnowReport>
  );
};

DorSnowReport.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string,
  locationId: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  isParentNavFeed: PropTypes.bool,
  filterBy: PropTypes.arrayOf(PropTypes.string),
  hide: PropTypes.arrayOf(PropTypes.string),
  showIcons: PropTypes.bool,
  navFeedLink: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorSnowReport.defaultProps = {
  layout: Layouts.STACKED,
  title: null,
  html: null,
  locationId: null,
  showIcons: false,
  isParentNavFeed: false,
  filterBy: ['overnight', '7 days'],
  hide: [],
  parentColorProfile: null,
  navFeedLink: null,
};
