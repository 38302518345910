import PropTypes from 'prop-types';
import React, {
  useEffect,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, WysiwygStyleWrapper } from '@powdr/components';
import { HideOptions } from '@powdr/constants';
import { getConditions } from '@powdr/stores/actions/conditions-action';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorAlert } from './styles';

export const DorAlert = ({
  hideTitle,
  hideDate,
  hideCopy,
  hide,
  isParentNavFeed,
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useConditionsData = useSelector((state) => state.conditions);
  const { isLoading, error, data } = useConditionsData;

  useEffect(() => {
    dispatch(getConditions());
  }, [dispatch]);

  const AlertRender = () => data?.dorAlert.map((alert) => {
    const titleFilter = hideTitle ? [HideOptions.TITLE] : [];
    const dateFilter = hideDate ? [HideOptions.DATE] : [];
    const copyFilter = hideCopy ? [HideOptions.COPY] : [];
    const hideFilter = [
      ...hide.map((el) => el.toLowerCase()),
      ...titleFilter,
      ...dateFilter,
      ...copyFilter,
    ];
    return (
      <div key={alert.title} className={`${alert.type}`}>
        {!hideFilter.includes(HideOptions.TITLE)
          && <h3>{alert.title}</h3>}
        {!hideFilter.includes(HideOptions.DATE)
          && <p>{alert.publication_date}</p>}
        {!hideFilter.includes(HideOptions.COPY)
          && <WysiwygStyleWrapper colorProfile={parentColorProfile}>{ReactHtmlParser(alert.html)}</WysiwygStyleWrapper>}
      </div>
    );
  });

  return (
    <StyledDorAlert
      colorProfile={parentColorProfile}
      isParentNavFeed={isParentNavFeed}
      className="dor-alert alert"
      property={property}
    >
      {
        isLoading ? (
          <Loader
            className="icon"
            classAnimationEffect="loader-animated spin infinite"
            iconName="dor-sunny"
            size="30"
          />
        ) : (
          AlertRender()
        )
      }
    </StyledDorAlert>
  );
};

DorAlert.propTypes = {
  hideTitle: PropTypes.bool,
  hideDate: PropTypes.bool,
  hideCopy: PropTypes.bool,
  hide: PropTypes.arrayOf(PropTypes.string),
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorAlert.defaultProps = {
  hideTitle: false,
  hideDate: false,
  hideCopy: false,
  hide: [],
  parentColorProfile: null,
};
