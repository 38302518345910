// eslint-disable-next-line import/no-unresolved
import { navigate } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { Icon, Link, StickyNav } from '@powdr/components';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import {
  getActiveLinkAtSetDepth, getLevelLink, getLevelName, isBuildTime,
} from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledPageTitle, PageBackButton, StyledPageHeader, ThirdLevelNav,
  InnerWrapper,
  AlignmentWrapper,
  StickyNavWrapper,
} from './styles';

export const PageHeader = ({
  title,
  showStickyNav,
  backButtonLink,
  levels,
}) => {
  if (isBuildTime()) return null;

  const { navigationDrawer: { navigation } } = useStaticData();
  const { isMobile } = useContext(AppContext);
  const { checkSeasonality } = useSeason();
  // Using this to decide whether to navigate to prev history or form parent page link below.
  const isUseHistory = (window?.history?.state || !backButtonLink) || false;

  const stickyNav = useMemo(
    () => getActiveLinkAtSetDepth(navigation?.childPages, levels, 1)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [],
    [checkSeasonality, levels, navigation],
  );

  const showThirdLevelNav = !(getActiveLinkAtSetDepth(stickyNav, levels, 2)?.isOpenChildrenInModals
    || getActiveLinkAtSetDepth(stickyNav, levels, 2)?.isShowBackBtnOnChildren);

  const pageNav = useMemo(
    () => ((showThirdLevelNav) ? getActiveLinkAtSetDepth(stickyNav, levels, 2)?.childPages
      ?.filter((page) => !page.isHideFromNav && checkSeasonality(page.season)) || [] : []),
    [checkSeasonality, levels, stickyNav, showThirdLevelNav],
  );

  return (
    <>
      <StickyNavWrapper>
        {(showStickyNav) && (
          <StickyNav
            levels={levels}
            data={stickyNav}
            mobileInnerNavData={pageNav}
          />
        )}
      </StickyNavWrapper>
      <StyledPageHeader>
        <InnerWrapper>
          <AlignmentWrapper>
            { !!(title) && (<StyledPageTitle>{title}</StyledPageTitle>)}
            {(!isMobile && pageNav.length > 1) && (
              <ThirdLevelNav role="navigation">
                <ul>
                  { pageNav.map((levelThreeItem) => (
                    <li className="t-item" key={levelThreeItem.id} role="menuitem">
                      { getLevelLink(levelThreeItem).includes(`/${levels[2]}`) && (
                      <Link
                        className={classNames('btn', { 'm-active': getLevelLink(levelThreeItem).endsWith(`/${levels[2]}`) })}
                        href={getLevelLink(levelThreeItem)}
                      >
                        {getLevelName(levelThreeItem)}
                      </Link>
                      )}
                      { !getLevelLink(levelThreeItem).includes(`/${levels[2]}`) && (
                      <Link
                        // TODO: find all m-active and btn classes and convert to styled components
                        className={classNames('btn', { 'm-active': getLevelLink(levelThreeItem).includes(`/${levels[0]}/${levels[1]}/${levels[2]}`) })}
                        href={getLevelLink(levelThreeItem)}
                      >
                        {getLevelName(levelThreeItem)}
                      </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </ThirdLevelNav>
            )}
            {(backButtonLink) && (
              <PageBackButton className="levels-back-button">
                { isUseHistory
                  ? (
                    <button type="button" onClick={() => navigate(-1)}>
                      Back
                      <Icon className="levels-back-icon" name="ui-back-arrow" width="20" />
                    </button>
                  )

                  : (
                    <Link
                      aria-label="Back"
                      href={backButtonLink}
                    >
                      <button type="button">
                        Back
                        <Icon className="levels-back-icon" name="ui-back-arrow" width="20" />
                      </button>
                    </Link>
                  )}
              </PageBackButton>
            )}
          </AlignmentWrapper>
        </InnerWrapper>
      </StyledPageHeader>
    </>
  );
};

PageHeader.propTypes = {
  backButtonLink: PropTypes.string,
  showStickyNav: PropTypes.bool.isRequired,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  backButtonLink: null,
  title: '',
};
