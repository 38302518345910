import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, FontFamily, FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledWysiwygStyleWrapper = styled.div`
  h1, h2, h3, h4, h5, h6 {
    a { font-size: inherit; }
    color: ${({ $colorProfile, $component }) => themeUtil(Prop.HEADER, $component, $colorProfile)};
  }

  h2 {
    ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined, $component, 'header2')};
    margin-bottom: 20px;

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined, $component, 'header2-mobile')};
    `)}
  }

  h3 {
    ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG10, undefined, undefined, $component, 'header3')};
    margin-bottom: 15px;

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG10, undefined, undefined, $component, 'header3-mobile')};
    `)}
  }

  h4 {
    ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED30, undefined, undefined, $component, 'header4')};
    margin-bottom: 10px;

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED30, undefined, undefined, $component, 'header4-mobile')};
    `)}
  }

  h5 {
    ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, $component, 'header5')};
    margin-bottom: 10px;

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, $component, 'header5-mobile')};
    `)}
  }

  h6 {
    ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, $component, 'header6')};
    margin-bottom: 5px;

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, $component, 'header6-mobile')};
    `)}
  }

  .subheader {
    ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, FontSize.MED30, undefined, undefined, $component, 'subheader')};
    margin-top: -10px;
    margin-bottom: 30px;

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined, $component, 'subheader-mobile')};
    `)}
  }

  p {
    line-height: 1.2;
    margin-bottom: 15px;
    ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, $component, 'body')};

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, 0.9, undefined, $component, 'body')};
    `)}

    ${Breakpoints.larger(css`
      ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined, $component, 'body')};
    `)}
  }

  a {
    ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, $component, 'body')};

    ${Breakpoints.mobile(css`
      ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, 0.9, undefined, $component, 'body')};
    `)}

    ${Breakpoints.larger(css`
      ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined, $component, 'body')};
    `)}
  }

  small, small a {
    ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, FontSize.REG30, undefined, undefined, $component, 'small')};
  }

  p, li, label {
    color: ${({ $colorProfile, $component }) => themeUtil(Prop.CONTENT, $component, $colorProfile)};
  }

  img {
    height: 100%;
    max-width: 100%;

    &.align-center {
      display: block;
      margin: 0 auto 15px auto;
    }

    &.align-left {
      float: left;
    }

    &.align-right {
      float: right;
    }
  }

  ul, ol {
    margin-bottom: 25px;
  }

  ul {
    &:not(.custom-dots) {
      li {
        list-style: none;
        ::before {
          content: "•";
          padding-right: 5px;
        }
      }
    }

    &.custom-dots li {
      margin-left: 0px !important;
      padding-left: 1.1em;
      background-image: url(${`/assets/${process.env.GATSBY_PROPERTY_NAME}/images/list-${process.env.GATSBY_PROPERTY_NAME}.svg`});
      background-repeat: no-repeat;
      background-position: left 4px;
      background-size: 12px 12px;
    }

    &.small-list-items li {
      ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, FontSize.REG30, undefined, undefined, $component, 'small')};
    }

    &:not(.small-list-items) li {
      ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined, $component, 'body')};

      ${Breakpoints.mobile(css`
        ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, 0.9, undefined, $component, 'body')};
      `)}

      ${Breakpoints.larger(css`
        ${({ $component }) => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined, $component, 'body')};
      `)}
    }

    li {
      margin-bottom: 5px;
      line-height: 1.25;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-bottom: 5px;
      line-height: 1.25;
    }
  }

  hr {
    margin: 30px 0;
    border: 1px solid ${({ $component, $colorProfile }) => themeUtil(Prop.BORDER, $component, $colorProfile)};
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 40px;
    width: 100%;

    tbody {
      width: 100%;
    }

    tr {
      display: table-row;
      border-bottom: 1px solid ${({ $colorProfile, $component }) => themeUtil(Prop.BORDER, $component, $colorProfile)};
    }

    td {
      .btn {
        @include bp-mobile {
          margin: 0 1px;
        }
      }

      .icon-c {
        margin: 0 auto;
      }
    }

    td,
    th {
      display: table-cell;
      color: ${({ $colorProfile, $component }) => themeUtil(Prop.CONTENT, $component, $colorProfile)};
      padding: 10px 15px 10px 15px;
      width: auto;
      line-height: 1.2em;

      svg {
        fill: ${({ $colorProfile, $component }) => themeUtil(Prop.ICON, $component, $colorProfile)};
        color: ${({ $colorProfile, $component }) => themeUtil(Prop.ICON, $component, $colorProfile)};
      }
    }

    th,
    td {
      text-align: center;
    }

    th:first-child,
    td:first-child {
      text-align: left;
    }

    th:last-child,
    td:last-child {
      text-align: right;
    }
  }

  .global-table {
    table {
      th:last-child,
      td:last-child {
        text-align: center;
      }
    }
  }

  .bold-cell {
    font-weight: bold;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    embed, iframe, object, img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .flex-buttons {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    ${Breakpoints.mobile(css`
      flex-direction: column;
    `)}
  }



  .center-xy {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  a:not(.btn), small a:not(.btn) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ $colorProfile, $component }) => themeUtil(Prop.LINK_TXT, $component, $colorProfile)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ $colorProfile, $component }) => themeUtil(Prop.LINK_TXT_HOVER, $component, $colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ $colorProfile, $component }) => themeUtil(Prop.LINK_TXT_HOVER, $component, $colorProfile)};
      outline-offset: 2px;
    }
  }

  .btn {
    ${({ $component }) => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined, $component, 'button')};
    line-height: 1;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ $colorProfile, $component }) => themeUtil(Prop.BTN_TXT, $component, $colorProfile)};
    background-color: ${({ $colorProfile, $component }) => themeUtil(Prop.BTN_BG, $component, $colorProfile)};
    font-weight: bold;
    transition: color .25s ease-out, background-color .25s ease-out;

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${({ $colorProfile, $component }) => themeUtil(Prop.BTN_TXT_HOVER, $component, $colorProfile)};
      background-color: ${({ $colorProfile, $component }) => themeUtil(Prop.BTN_BG_HOVER, $component, $colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ $colorProfile, $component }) => themeUtil(Prop.BTN_BG_HOVER, $component, $colorProfile)};
      outline-offset: 2px;
    }
  }

  iframe {
    overflow: hidden;
  }
`;
