import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  Alignment,
  BlockTypes, Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp, getFontStyles } from '@powdr/utils';

const CPNT = BlockTypes.STAT;

// styled components
const ContentBlockStat = styled(Link)`
  display: flex;
  gap: 15px;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  width: 100%;
  z-index: 1;

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  ${({ $alignment }) => (($alignment === Alignment.CENTER) ? `
    flex-direction: column;
    justify-content: top;
    position: relative;
    width: 100%;
  ` : '')};
`;

const ContentContainer = styled.div`
  ${({ $alignment }) => (($alignment === Alignment.CENTER) ? `
    text-align: center;
  ` : `
    width: 40%;
    padding-top: 10px;
    text-align: left;
  `)};

  .cb-title {
    margin-bottom: ${({ $alignment }) => (($alignment === Alignment.CENTER) ? '15px' : '0px')};
    ${() => clamp(2)}
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG50, undefined, undefined)};

    ${Breakpoints.smallInbetween(css`
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined)};
    `)}
  }

  .cb-title, .cb-copy {
    max-width: 100%;
  }

  .cb-copy {
    ${() => clamp(5)}
    line-height: 1.5rem;
    text-transform: ;

    ${Breakpoints.mobile(css`
      padding-bottom: 50px;
    `)}
  }
`;

const IconContainer = styled.div`
  display: block;
  width: ${({ $isImage }) => (($isImage) ? '65px' : 'auto')};
  height: 65px;

  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
  }
`;

const Image = styled.img`
  display: block;
  height: 100%;
`;

export const Stat = ({
  title, copy, blockType, ariaLabel,
  buttonLink, buttonTarget,
  colorProfile, image, iconName, contentAlignment, isStaticImageLink,
}) => (
  <ContentBlockStat
    href={buttonLink}
    target={buttonTarget}
    ariaLabel={ariaLabel}
    role="button"
    colorProfile={colorProfile}
    className={`content-block-${blockType}`}
    $alignment={contentAlignment || Alignment.CENTER}
  >
    <IconContainer colorProfile={colorProfile} $isImage={(!iconName && image)}>
      {(iconName) && <Icon name={iconName} height="65" />}
      {(!iconName && image && !isStaticImageLink) && <GatsbyImage className="stat-image" image={image.src} alt={image?.altText} />}
      {(!iconName && image && isStaticImageLink) && <Image className="stat-image" src={image.src} alt={image?.altText} />}
    </IconContainer>
    <ContentContainer $alignment={contentAlignment || Alignment.CENTER}>
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(copy) && <p className="cb-copy">{copy}</p>}
    </ContentContainer>
  </ContentBlockStat>
);

Stat.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;

Stat.defaultProps = {
  contentAlignment: Alignment.CENTER,
};
