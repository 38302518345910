/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-unresolved
import { format, compareAsc, parse } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import {
  Icon, UiTable, Slider,
  UiColumn,
  CollapsePanelGroup,
  WysiwygStyleWrapper,
  HtmlContentLoader,
  Link,
} from '@powdr/components';
import { Components, VerticalPosition } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import {
  textFilter, cleanSliderData, cleanTableData,
  colorProfileByIndex,
} from '@powdr/utils';

import {
  StyledTemplatedContent, NewsStyleDate,
  DynamicTitle, DynamicBody, NewsStyleImage,
  StartEndDate, DateContainer, RowSection,
  ContentContainer, SplitContentContainer,
  LocationHoursContainer, DynamicContactInfo,
  ContactInfoSection, DynamicHours, DynamicMenu,
  DynamicPrice, TemplatedContentInnerWrapper,
  DynamicBodySplit,
  TemplatedContentMaxWidthWrapper,
  MaxWidthWrapper,
  InnerWrapper,
  LocationSection,
  DropdownsWrapper,
  DropdownsWrapperHeader,
  Dropdowns,
} from './styles';

export const TemplatedContent = ({
  fields,
  isParentModal,
}) => {
  const { themeConfig: { modal } } = useContext(AppContext);
  const {
    title, body: bodyContent,
    field_date: dateString, field_end_date: endDateString,
    field_ui_table: tableContent, field_slider: sliderAPI, field_image: image,
    field_author: author, field_contact_name: contactName,
    field_contact_email: contactEmail, field_contact_phone: contactPhone,
    field_hours: hours, field_menu: menu, field_price_range: priceRange,
    field_location: location, field_location_details: locationDetails,
    field_phone_number: phoneNumber, field_phone_number_details: phoneNumberDetails,
    additionalContent, columnContent, sidebarContent, relationships: components,
    dropdownsColorProfile, dropdownsHeader,
  } = fields;
  const body = (bodyContent?.value) ? bodyContent.value : bodyContent;
  const slider = (components?.slider) ? components.slider : sliderAPI;
  const table = (tableContent?.rows) ? tableContent.rows : tableContent;
  const dropdowns = components?.dropdowns;
  const hoursContent = (hours?.value) ? hours.value : (hours && typeof hours === 'string') ? `<p>${hours}</p>` : null;
  const columnGallery = components?.columnGallery;

  const startDate = (dateString) ? parse(dateString, 'yyyy-MM-dd', new Date()) : null;
  const endDate = (endDateString) ? parse(endDateString, 'yyyy-MM-dd', new Date()) : null;
  const newsStyleModal = false;
  const isSplitContent = (slider || sidebarContent?.value);

  return (
    <StyledTemplatedContent
      $splitContent={isSplitContent}
      $isParentModal={isParentModal}
    >
      <InnerWrapper>
        <MaxWidthWrapper>
          {(title || body || table || slider) && (
            <RowSection>
              <ContentContainer $splitContent={isSplitContent}>
                {(startDate && newsStyleModal) && <NewsStyleDate>{format(startDate, 'MMMM d, yyyy')}</NewsStyleDate>}
                {(title) && <DynamicTitle>{textFilter(title)}</DynamicTitle>}
                {(image && newsStyleModal) && <NewsStyleImage />}
                {((startDate || endDate) && !newsStyleModal) && (
                <DateContainer>
                  <Icon name="content-calendar" width="25" height="25" />
                  <StartEndDate>
                    {`${format(startDate, 'MMMM d, yyyy')}`}
                    {(endDate && compareAsc(startDate, endDate) !== 0) ? ` - ${format(endDate, 'MMMM d, yyyy')}` : ''}
                  </StartEndDate>
                </DateContainer>
                )}
                {((location && location !== '') || (phoneNumber && phoneNumber !== '') || hoursContent) && (
                <LocationHoursContainer>
                  {(hoursContent) && (
                  <DynamicHours>
                    {(priceRange) && <DynamicPrice max={4} value={priceRange} character="$" />}
                    <HtmlContentLoader html={hoursContent} />
                  </DynamicHours>
                  )}
                  {(location !== '' || phoneNumber !== '') && (
                  <DynamicContactInfo>
                    <LocationSection>
                      {(location && location !== '') && <small className="bold">{location}</small>}
                      {(locationDetails && locationDetails !== '') && <small className="bold">{`${locationDetails}`}</small>}
                    </LocationSection>
                    <ContactInfoSection>
                      {(phoneNumber && phoneNumber !== '') && <small><Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link></small>}
                      {(phoneNumberDetails && phoneNumberDetails !== '') && <small>{`, ${phoneNumberDetails}`}</small>}
                    </ContactInfoSection>
                  </DynamicContactInfo>
                  )}
                </LocationHoursContainer>
                )}
                {(body) && (
                  <DynamicBody>
                    <WysiwygStyleWrapper component={Components.MODAL}>
                      <HtmlContentLoader html={body} />
                    </WysiwygStyleWrapper>
                  </DynamicBody>
                )}
                {(sidebarContent?.value) && (
                  <DynamicBodySplit className="mobile-sidebar-content">
                    <HtmlContentLoader html={sidebarContent.value} />
                  </DynamicBodySplit>
                )}
                {(menu?.value) && (
                  <DynamicMenu>
                    <WysiwygStyleWrapper component={Components.MODAL}>
                      <HtmlContentLoader html={menu.value} />
                    </WysiwygStyleWrapper>
                  </DynamicMenu>
                )}
                {(author) && <p>{author}</p>}
                {(contactName) && <p>{contactName}</p>}
                {(contactEmail) && <p>{contactEmail}</p>}
                {(contactPhone) && <p>{contactPhone}</p>}
              </ContentContainer>
              {(slider || sidebarContent) && (
                <SplitContentContainer>
                  {(slider) && (
                    <Slider {...cleanSliderData(slider)} parentColorProfile={modal} />
                  )}
                  {(sidebarContent?.value) && (
                    <DynamicBodySplit className="desktop-sidebar-content">
                      <HtmlContentLoader html={sidebarContent.value} />
                    </DynamicBodySplit>
                  )}
                </SplitContentContainer>
              )}
            </RowSection>
          )}
          {(table && Object?.values(table)?.filter((x) => x)?.length > 0) && (
            <UiTable
              tables={[{ rows: cleanTableData(table) }]}
              parentColorProfile={modal}
            />
          )}
          {(additionalContent?.value) && (
            <DynamicBody>
              <WysiwygStyleWrapper component={Components.MODAL}>
                <HtmlContentLoader html={additionalContent.value} />
              </WysiwygStyleWrapper>
            </DynamicBody>
          )}
          {(columnContent?.value && columnGallery) && (
            <UiColumn
              relationships={{
                columnComponents: [
                  columnGallery,
                  {
                    id: Math.random(),
                    typeName: Components.UI_HTML,
                    html: { value: columnContent?.value },
                  },
                ],
              }}
              contentPosition={VerticalPosition.MIDDLE}
            />
          )}
          {(dropdowns?.relationships) && (
            <DropdownsWrapper>
              {(dropdownsHeader && dropdownsHeader !== '') && (
                <DropdownsWrapperHeader>
                  {dropdownsHeader}
                </DropdownsWrapperHeader>
              )}
              <Dropdowns
                parentColorProfile={colorProfileByIndex(dropdownsColorProfile)}
                relationships={dropdowns.relationships}
                itemGap={30}
              />
            </DropdownsWrapper>
          )}
        </MaxWidthWrapper>
      </InnerWrapper>
    </StyledTemplatedContent>
  );
};

TemplatedContent.propTypes = {
  fields: PropTypes.shape().isRequired,
  isParentModal: PropTypes.bool,
};

TemplatedContent.defaultProps = {
  isParentModal: true,
};
