import PropTypes from 'prop-types';
import React, { useEffect, useContext, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { DorForecastItem, PrimaryCarousel, WysiwygStyleWrapper } from '@powdr/components';
import { CarouselControlPlacement } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getWeather } from '@powdr/stores/actions/weather-action';

import {
  StyledDorWeather,
  StaticHeader,
  OpenWeatherAttribution,
  ForecastList, ForecastListItem,
} from './styles';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const DorWeather = ({
  title,
  html,
  parentColorProfile,
  isParentNavFeed,
  isStaticForecast,
  descriptionLength,
  staticItems,
}) => {
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const { data } = useWeatherData;
  const isWeatherAttribution = gatsbyConfig?.dor?.attributionRequired || false;
  const { isMobile } = useContext(AppContext);

  useEffect(() => {
    dispatch(getWeather());
  }, [dispatch]);

  const ForecastMap = (items) => useMemo(() => items?.map((item) => (
    <ForecastListItem
      key={`${item?.day?.short}-${Math.random()}`}

    >
      <DorForecastItem
        icon={item?.icon}
        temperature={{
          min: item?.temp?.min,
          max: item?.temp?.max,
        }}
        snowfall={{
          min: item?.snow?.min,
          max: item?.snow?.max,
        }}
        date={item?.day?.long}
        description={(item?.forecast?.long && descriptionLength === 'long')
          ? item.forecast.long
          : item?.forecast?.short || null}
        isFitToContent={isParentNavFeed}
      />
    </ForecastListItem>
  )), [items]);

  return (
    <StyledDorWeather
      colorProfile={parentColorProfile}
      isParentNavFeed={isParentNavFeed}
    >
      <>
        {(!isParentNavFeed && (title || html)) && (
          <StaticHeader>
            {title && (<h3>{title}</h3>)}
            {html && <WysiwygStyleWrapper colorProfile={parentColorProfile}>{ReactHtmlParser(html)}</WysiwygStyleWrapper>}
          </StaticHeader>
        )}
        {(!isStaticForecast && isWeatherAttribution) && (
          <OpenWeatherAttribution aria-label="Powered By OpenSnow" href={data?.attribution?.link_url}>
            Powered By:
            <img src={data?.attribution?.image_light_url} alt="" />
          </OpenWeatherAttribution>
        )}
        <ForecastList>
          {isParentNavFeed || isMobile ? (
            <PrimaryCarousel
              controlSettings={{
                navigation: {
                  iconName: 'ui-arrow-left',
                  size: 30,
                  placement: CarouselControlPlacement.IN_WRAPPER,
                },
              }}
              breakpoints={{
                960: {
                  slidesPerView: 2,
                },
                1366: {
                  slidesPerView: 3,
                },
              }}
              slidesPerView={isMobile ? 1 : 3}
              isInfiniteScroll={false}
              colorProfile={parentColorProfile}
            >
              {ForecastMap((!isStaticForecast) ? data?.dorWeather || [] : staticItems)}
            </PrimaryCarousel>
          ) : ForecastMap((!isStaticForecast) ? data?.dorWeather || [] : staticItems)}
        </ForecastList>
      </>
    </StyledDorWeather>
  );
};

DorWeather.propTypes = {
  title: PropTypes.string,
  html: PropTypes.string,
  isParentNavFeed: PropTypes.bool,
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isStaticForecast: PropTypes.bool,
  descriptionLength: PropTypes.string,
  staticItems: PropTypes.arrayOf(),
};

DorWeather.defaultProps = {
  title: null,
  html: null,
  isParentNavFeed: false,
  parentColorProfile: null,
  isStaticForecast: false,
  descriptionLength: 'short',
  staticItems: null,
};

/*
 */
