import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { Icon, Link, WysiwygStyleWrapper } from '@powdr/components';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp } from '@powdr/utils';

const CPNT = BlockTypes.BASIC_ALT;

// styled components
const ContentBlockBasicAlt = styled(Link)`
  .basic-alt-container {
    position: relative;
    margin-bottom: 50px;

    .cb-title, .cb-copy, .button-wrapper, .image-wrapper {
      text-align: center;
    }

    .image-wrapper {
      margin-bottom: 20px;
    }
  }

  .cb-title, .cb-copy {
    margin-bottom: 20px;
  }

  .cb-title {
    ${() => clamp(3)}
  }

  .cb-copy {
    ${() => clamp(6)}
    line-height: 1.35rem;
  }

  .button {
    display: inline-block;
  }

  &:hover .button, &:focus .button {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  &:focus .button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 20px;

  ${({ $icon, $image }) => (($icon && !$image) ? 'padding: 0 10%; max-height: 150px;' : '')}
`;

const Image = styled.img``;

export const BasicAlt = ({
  title, copy, blockType, colorProfile, image, isStaticImageLink, ariaLabel, iconName,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick, formattedContent,
}) => (
  <ContentBlockBasicAlt
    href={buttonLink}
    target={buttonTarget}
    colorProfile={colorProfile}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    className={`content-block-${blockType} `}
  >
    <div className="basic-alt-container">
      <ImageContainer $icon={!!(iconName)} $image={!!(image)}>
        {(image && !isStaticImageLink) && <GatsbyImage image={image.src} alt={image?.altText} />}
        {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
        {(!image && iconName) && <Icon className="cb-icon" name={iconName} />}
      </ImageContainer>
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(copy) && (<p className="cb-copy">{copy}</p>)}
      {(formattedContent) && <WysiwygStyleWrapper className="cb-formatted-content">{ReactHtmlParser(formattedContent)}</WysiwygStyleWrapper>}
      {(buttonText && buttonEnabled) && (
        <div className="button-wrapper">
          <div className="button with-background " aria-label={ariaLabel}>{buttonText}</div>
        </div>
      )}
    </div>
  </ContentBlockBasicAlt>
);

BasicAlt.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
