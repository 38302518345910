import {
  FontSize,
  FontFamily,
  TextTransformOptions,
  Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"HCo Gotham Book"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"BlockGothicRR-ExtraBoldCond"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.MED50,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"HCo Gotham Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG40,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.WYSIWYG]: {
    header2: {
      size: '50px',
    },
    header3: {
      size: '30px',
    },
    header4: {
      size: '30px',
    },
    header5: {
      size: '30px',
    },
    header6: {
      size: '30px',
    },
  },
  [Components.COMPARE_CARDS]: {
    title: {
      font: FontFamily.BASE_FONT,
    },
  },
  [Components.UI_TABLE]: {
    'data-header': {
      size: FontSize.REG30,
    },
  },
};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
