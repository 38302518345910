/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { HeaderLogo, Link } from '@powdr/components';
import * as DemoComponents from '@powdr/components/demos';
import { DemoColorProfileToggle, DemoSeasonToggle, DemoPageJump } from '@powdr/components/demos';
import {
  ColorProfiles, Components, FontFamily, FontSize, TextTransformOptions, ThemeProps as Prop,
} from '@powdr/constants';
import {
  componentColor,
  deslugify, getFontStyles, themeUtil,
} from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

const CPNT = Components.HEADER;

const DemoContainer = styled.div`
  ${({ offset }) => `margin: ${offset + 30}px 15px 30px 15px;`};
`;

const DemoHeader = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  .header-logo {
    padding: 10px 0;
    background-color: ${() => componentColor(Prop.NAV_BG, CPNT, Prop.BTN_BG)};
  }
`;

const MainHeaderContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  height: 100px;
`;

const NavigationContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const NavItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, TextTransformOptions.UPPERCASE)};
  text-align: center;
  line-height: 1em;
  overflow: hidden;
  padding: 0 5px;
  text-transform: uppercase;
  transition: 0.25s;

  ${({ active }) => ((active) ? `
    color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
    background-color: ${themeUtil(Prop.BTN_BG_ACTIVE, CPNT)};
  ` : `
    color: ${themeUtil(Prop.BTN_TXT, CPNT)};
    background-color: ${themeUtil(Prop.BTN_BG, CPNT)};
  `)}

  &:hover {
    color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
  }
`;

export const Demos = ({ data }) => {
  const DemoComponent = DemoComponents?.[`${data.type}`];
  const { pathname } = useLocation();
  const { headerConfig } = useStaticData();
  const headerRef = useRef(null);
  const [activeColorProfile, setActiveColorProfile] = useState(ColorProfiles.BASE);

  return (
    <DemoContainer offset={headerRef?.current?.clientHeight}>
      <DemoHeader ref={headerRef}>
        <MainHeaderContainer>
          <HeaderLogo isFromDemo />
          <NavigationContainer>
            {Object.values(data.demoTaxonomy).map((item) => (
              <NavItem
                key={item.route}
                active={pathname === `/demo/${item.route}`}
                href={`/demo/${item.route}`}
              >
                {deslugify(item.route)}
              </NavItem>
            ))}
          </NavigationContainer>
        </MainHeaderContainer>
        {headerConfig.isSeasonalityEnabled && <DemoSeasonToggle />}
        {(data.type !== 'ContentBlocksDemo') && <DemoColorProfileToggle onSelectColorProfile={setActiveColorProfile} />}
        <DemoPageJump />
      </DemoHeader>
      <DemoComponent colorProfile={activeColorProfile} />
    </DemoContainer>
  );
};

Demos.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

Demos.defaultProps = {

};
