import styled from 'styled-components';

import { ThemeProps as Prop, Components } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.COLLAPSE_PANEL_GROUP;

export const StyledCollapsePanelGroup = styled.div`
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};

  > :first-child {
    padding: 0;
  }

  > :not(:first-child) {
    padding: ${({ $itemGap }) => $itemGap}px 0 0 0;
  }
`;
