// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';

import { HeaderLogo, Icon } from '@powdr/components';
import { AbsolutePositions, AlignmentShorthand, Components } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useIsInView } from '@powdr/hooks';

import { ChildNavDrawer } from '../main-nav/components';

import {
  ChildrenWrapper,
  ComponentDrawerHeader, DrawerCloseButton, InterceptPoint, LogoWrapper, StyledComponentDrawer,
} from './styles';

export const ComponentDrawer = ({
  isOpen,
  slideInFrom,
  closeDrawerFunc,
  closeButtonPosition,
  componentTheme,
  isShowLogoHeader,
  className,
  children,
}) => {
  const { isMobile } = useContext(AppContext);
  const interceptRef = useRef();
  const isShowHeaderShadow = useIsInView(interceptRef);

  return (
    <StyledComponentDrawer
      className={className}
      $componentTheme={componentTheme}
      $isOpen={isOpen || false}
      $slideInFrom={slideInFrom || AbsolutePositions.RIGHT}
    >
      {(isShowLogoHeader) ? (
        <ComponentDrawerHeader $isShowShadow={!isShowHeaderShadow}>
          <LogoWrapper>
            <HeaderLogo isFromDrawer />
          </LogoWrapper>
          {/* Close Button */}
          <DrawerCloseButton
            onClick={closeDrawerFunc}
            $position={closeButtonPosition || AlignmentShorthand.TOP_RIGHT}
          >
            <Icon className="drawer-close-button" name="ui-close" width={(isMobile) ? 25 : 40} />
          </DrawerCloseButton>
        </ComponentDrawerHeader>
      ) : (
        <DrawerCloseButton
          onClick={closeDrawerFunc}
          $position={closeButtonPosition || AlignmentShorthand.TOP_RIGHT}
          $isShowShadow={!isShowHeaderShadow}
        >
          <Icon className="drawer-close-button" name="ui-close" width={(isMobile) ? 25 : 40} />
        </DrawerCloseButton>
      )}
      <ChildrenWrapper $isShowLogoHeader={isShowLogoHeader}>
        <InterceptPoint ref={interceptRef} />
        {children}
      </ChildrenWrapper>
    </StyledComponentDrawer>
  );
};

ComponentDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  slideInFrom: PropTypes.oneOf(Object.values(AbsolutePositions)).isRequired,
  closeButtonPosition: PropTypes.oneOf(Object.values(AlignmentShorthand)),
  componentTheme: PropTypes.string,
  closeDrawerFunc: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ComponentDrawer.defaultProps = {
  componentTheme: Components.COMPONENT_DRAWER,
  closeButtonPosition: AlignmentShorthand.TOP_RIGHT,
};
